// CloudCannon Editor
.editor-link {
  display: none;
}

.cms-editor-active {
  .editor-link {
    display: block;
  }
  .media-right {
    position: relative;
    .editor-link {
      bottom: 0;
      position: absolute;
      right: 2em;
    }
  }
  header {
    position: relative;
    z-index: 1;
    & > .editor-link {
      left: 1em;
      position: absolute;
      top: 8.6em;
      @media only screen and (min-width: 48em) {
        left: auto;
        margin-top: 1em;
        right: 1em;
        top: 100%;
      }
    }
  }
  #backdrop > .editor-link {
    position: absolute;
    right: 1em;
    top: 8.6em;
    @media only screen and (min-width: 48em) {
      left: 1em;
      right: auto;
      top: 1em;
      z-index: 2;
    }
  }
  #donate-btns {
    position: relative;
    & > .editor-link {
      font-size: 14px;
      left: 50%;
      margin: 4px 0 0;
      position: absolute;
      @include transform(translateX(-50%));
      top: 100%;
      width: auto;
    }
  }
}
