/*!
 * Bootstrap v3.3.6 (http://getbootstrap.com)
 * Copyright 2011-2015 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

// Core variables and mixins
@import "bootstrap_slim/variables";
@import "bootstrap_slim/mixins";

// Reset and dependencies
@import "bootstrap_slim/normalize";
@import "bootstrap_slim/print";

// Core CSS
@import "bootstrap_slim/scaffolding";
@import "bootstrap_slim/type";
@import "bootstrap_slim/grid";
@import "bootstrap_slim/forms";
@import "bootstrap_slim/buttons";

// Components
@import "bootstrap_slim/media";

// Utility classes
@import "bootstrap/utilities";
@import "bootstrap/responsive-utilities";
