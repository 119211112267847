.language-switch {
  background-color: #F5F8FC;
  color: #58574e;
  display: inline-block;
  float: left;
  font-size: 18px;
  line-height: 1.25;
  margin-left: 2rem;
  outline: none;
  padding: .5em 1.2em;
  text-decoration: none;
  text-transform: uppercase;
  &:focus, &:hover {
    color: #58574e;
    text-decoration: none;
  }
}

@media only screen and (max-width: 767px) {
  .language-switch-container, .language-switch-container .row {
    display: inline;
    &:before, &:after {
      display: none;
    }
  }
  .language-switch {
    float: none;
    margin-left: 0;
  }
}
