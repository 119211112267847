// Scaffolding

html {
  font-size: 100%;
}

body {
  font-size: 1em;
  min-width: 18em;
}

* {
  -webkit-backface-visibility: hidden;
}

// Type

h1, h2, h3 {
  margin-top: 1.25rem;
  margin-bottom: .625rem;
}

h4, h5, h6 {
  margin-top: .625rem;
  margin-bottom: .625rem;
}

h1 {
  font-size: 2.25rem;
}

h2 {
  font-size: 1.875rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.125rem;
}

h5 {
  font-size: .875rem;
}

h6 {
  font-size: .75rem;
}

p {
  margin-bottom: .625rem;
}

.text-light { font-weight: 300; }
.text-mute1 { color: #4c4c4c; }
.text-mute2 { color: #8a8a8a; }

.lead {
  font-size: 1.125em;
  font-weight: normal;
}

small, .small {
  font-size: 80%;
}

// Buttons

.btn-lg {
  font-size: 1.125em;
  padding: .625rem 1rem;
}

.btn-wide {
  max-width: 100%;
  width: 15.3125rem;
}

// Form elements

.form-control {
  color: $text-color;
  font-size: 1em;
}

select.form-control {
  cursor: pointer;
  height: 2.2em;
  padding: .3em .6em;
}

input[type="number"].no-spinner {
  -moz-appearance:textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

// Utilities

.p-t-1 { padding-top: .6rem; }
.p-t-2 { padding-top: 1.2rem; }
.p-t-3 { padding-top: 1.8rem; }
.p-t-4 { padding-top: 2.4rem; }

.p-r-1 { padding-right: .6rem; }
.p-r-2 { padding-right: 1.2rem; }
.p-r-3 { padding-right: 1.8rem; }
.p-r-4 { padding-right: 2.4rem; }
.p-r-5 { padding-right: 3rem; }
.p-r-6 { padding-right: 3.6rem; }

.p-b-1 { padding-bottom: .6rem; }
.p-b-2 { padding-bottom: 1.2rem; }
.p-b-3 { padding-bottom: 1.8rem; }
.p-b-4 { padding-bottom: 2.4rem; }

.p-l-1 { padding-left: .6rem; }
.p-l-2 { padding-left: 1.2rem; }
.p-l-3 { padding-left: 1.8rem; }
.p-l-4 { padding-left: 2.4rem; }
.p-l-5 { padding-left: 3rem; }
.p-l-6 { padding-left: 3.6rem; }

.m-b-1 { margin-bottom: .6rem; }

// Arrow down

.arrow-down {
  padding-bottom: 2.5em;
  position: relative;
  &:before, &:after {
    bottom: 0;
    border: solid transparent;
    border-bottom-color: white;
    content: '';
    display: block;
    height: 0;
    position: absolute;
    width: 50%;
    z-index: 1;
  }
  &:before {
    border-width: 0 3em 2.5em 0;
    left: 0;
  }
  &:after {
    border-width: 0 0 2.5em 3em;
    right: 0;
  }
}

// Animations

@-webkit-keyframes rotating {
  from { -webkit-transform: rotate(0deg); }
  to { -webkit-transform: rotate(359deg); }
}
@keyframes rotating {
  from { transform: rotate(0deg); }
  to { transform: rotate(359deg); }
}
.rotating {
  -webkit-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}

@-webkit-keyframes shake {
  0%, 100% { -webkit-transform: translateX(0); }
  17%, 50% { -webkit-transform: translateX(-.6em); }
  33%, 67% { -webkit-transform: translateX(.6em); }
  83%      { -webkit-transform: translateX(-.2em); }
}
@keyframes shake {
  0%, 100% { transform: translateX(0); }
  17%, 50% { transform: translateX(-.6em); }
  33%, 67% { transform: translateX(.6em); }
  83%      { transform: translateX(-.2em); }
}
.shake {
  -webkit-animation: shake .4s linear;
  animation: shake .4s linear;
}
