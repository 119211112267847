@charset "utf-8";

@import "fundraise";

#backdrop-xs {
  background-image: url("/images/background_site_mobile.jpeg");
}

@media only screen and (min-width: 48em) {
  #backdrop {
    background-image: url("/images/background_site_desktop.jpeg");
  }
  // #logo {
  //   background: rgba(255,255,255,.8);
  // }
}

