// Variables

$brand-primary: #4BC06E; // Green
$brand-color-2: #EF4035; // Red
$brand-color-3: #9a0040; // Burgundy
$brand-color-4: #f8f8f8; // Light gray
$brand-color-5: #cccdce; // Dark gray

$text-color: black;
$font-family-sans-serif: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
$link-color: #3d63ba; // Blue

$btn-border-radius-base: 0;
$btn-border-radius-large: 0;
$btn-border-radius-small: 0;
