.tab-links {
  counter-reset: tabs;
}

.tab-link {
  background: transparent;
  border: 0;
  cursor: pointer;
  display: inline-block;
  font-size: .75em;
  padding: 1em;
  text-align: left;
  text-transform: uppercase;

  @media (max-width: 767px) {
    &:not(.active) {
      clip: rect(0,0,0,0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      visibility: hidden;
      width: 1px;
    }
  }

  &:before {
    color: #888;
    content: "Step " counter(tabs) " :";
    counter-increment: tabs;
    margin-right: .5em;
  }
  &.active {
    cursor: default;
    display: block;
  }
  &[disabled] {
    cursor: default;
    -webkit-filter: grayscale(50%); filter: grayscale(50%);
    opacity: .6;
  }
}

button.tablink {
  &:active, &:focus, &:hover {
    color: currentColor;
    text-decoration: none;
  }
  &[disabled] {
    cursor: default;
    &:hover {
      color: currentColor;
    }
  }
}

.tabs {
  section {
    padding: 1em;
  }
  footer {
    padding-top: 1em;
  }
}

.tabs {
  display: flex;
  @include transition(transform .2s);
  width: 300%;
  section {
    display: flex;
    flex-direction: column;
    width: 33.3333333333%;
    .form-group {
      flex-grow: 2;
    }
  }
  &[data-show-tab="1"] {
    @include transform(translateX(0));
  }
  &[data-show-tab="2"] {
    @include transform(translateX(-33.3333333333%));
  }
  &[data-show-tab="3"] {
    @include transform(translateX(-66.6666666666%));
  }
}

.tab {
  visibility: hidden;
  &.active {
    visibility: visible;
  }
}
