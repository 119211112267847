.icon {
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  height: 1em;
  width: 1em;
}
.icon-calendar {
  background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M20%2020h-4v-4h4v4zm-6-10h-4v4h4v-4zm6%200h-4v4h4v-4zM8%2016H4v4h4v-4zm6%200h-4v4h4v-4zm-6-6H4v4h4v-4zm16-8v22H0V2h3v1c0%201%201%202%202%202s2-1%202-2V2h10v1c0%201%201%202%202%202s2-1%202-2V2h3zm-2%206H2v14h20V8zm-2-7c0-.6-.4-1-1-1s-1%20.4-1%201v2c0%20.6.4%201%201%201s1-.4%201-1V1zM6%203c0%20.6-.4%201-1%201s-1-.4-1-1V1c0-.6.4-1%201-1s1%20.4%201%201v2z%22%2F%3E%3C%2Fsvg%3E);
}
.icon-credit-card {
  background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M0%208V6c0-1%201-2%202-2h20c1%200%202%201%202%202v2H0zm24%203v7c0%201-1%202-2%202H2c-1%200-2-1-2-2v-7h24zM9%2016H3v1h6v-1zm3-2H3v1h9v-1zm9%200h-3v1h3v-1z%22%2F%3E%3C%2Fsvg%3E);
}
.icon-envelope {
  background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M12%2012.7L0%203h24l-12%209.7zM6.6%2011L0%205.5V18l6.6-7zm10.8%200l6.6%207V5.7L17.4%2011zM16%2012l-4%203-4-3L0%2021h24l-8-8.8z%22%2F%3E%3C%2Fsvg%3E);
}
.icon-phone {
  background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M20%2022.62l-3.52-6.8-2.07%201.02c-2.23%201.08-6.78-7.82-4.6-9l2.1-1.02L8.4%200%206.3%201.04C-.9%204.8%2010.5%2027.02%2017.9%2023.64l2.1-1.02z%22%2F%3E%3C%2Fsvg%3E);
}
.icon-home {
  background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M21%2013v10h-6v-6H9v6H3V13H0L12%201l12%2012z%22%2F%3E%3C%2Fsvg%3E);
}
.icon-info {
  background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M13.3%207c0%20.7-.6%201.3-1.3%201.3s-1.3-.6-1.3-1.3.6-1.3%201.3-1.3%201.3.6%201.3%201.3zM24%2012c0%206.6-5.4%2012-12%2012S0%2018.6%200%2012%205.4%200%2012%200s12%205.4%2012%2012zm-2%200c0-5.5-4.5-10-10-10S2%206.5%202%2012s4.5%2010%2010%2010%2010-4.5%2010-10zM9%2010v2h2v6h2v-8H9z%22%2F%3E%3C%2Fsvg%3E);
}
.icon-lock {
  background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M18%2010V6c0-3.3-2.7-6-6-6S6%202.7%206%206v4H3v14h18V10h-3zM8%2010V6c0-2.2%201.8-4%204-4s4%201.8%204%204v4H8z%22%2F%3E%3C%2Fsvg%3E);
}
.icon-loading {
  background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20fill%3D%22%23000%22%20d%3D%22M1%2012c0%201%20.2%202%20.4%203h2c-.2-1-.4-2-.4-3%200-5%204-9%209-9%201.7%200%203.3.5%204.7%201.3l-2%202.7h7l-2-7-2%202.7C16.2%201.7%2014.2%201%2012%201%206%201%201%206%201%2012zm6.4%207.7c1.3.8%203%201.3%204.6%201.3%205%200%209-4%209-9%200-1-.2-2-.5-3h2c.3%201%20.5%202%20.5%203%200%206-5%2011-11%2011-2%200-4-.6-5.8-1.7L4.4%2024l-2.2-7h7l-1.8%202.7z%22%2F%3E%3C%2Fsvg%3E);
}
.icon-loading-white {
  background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20fill%3D%22%23fff%22%20d%3D%22M1%2012c0%201%20.2%202%20.4%203h2c-.2-1-.4-2-.4-3%200-5%204-9%209-9%201.7%200%203.3.5%204.7%201.3l-2%202.7h7l-2-7-2%202.7C16.2%201.7%2014.2%201%2012%201%206%201%201%206%201%2012zm6.4%207.7c1.3.8%203%201.3%204.6%201.3%205%200%209-4%209-9%200-1-.2-2-.5-3h2c.3%201%20.5%202%20.5%203%200%206-5%2011-11%2011-2%200-4-.6-5.8-1.7L4.4%2024l-2.2-7h7l-1.8%202.7z%22%2F%3E%3C%2Fsvg%3E);
}
.icon-user {
  background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M20.8%2018c-3.4-.7-6.6-1.4-5-4.3C20.4%204.7%2017%200%2012%200S3.5%205%208.3%2013.7c1.6%203-1.8%203.6-5%204.4C0%2019%200%2020.4%200%2023v1h24v-1c0-2.7%200-4.2-3.2-5z%22%2F%3E%3C%2Fsvg%3E);
}
.icon-cross {
  background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%3E%3Cpath%20d%3D%22M1.6%203L3%201.7%2014.5%2013%2013%2014.3zM13%201.7L14.3%203%203%2014.5%201.7%2013z%22%2F%3E%3C%2Fsvg%3E);
}
