/* Regular sized checkbox on left-hand-side */
.xcheckbox {

  font-weight: normal;

  /* Base styling */
  [type="checkbox"]:not(:checked),
  [type="checkbox"]:checked {
    left: -9999px;
    position: absolute;
  }
  [type="checkbox"]:not(:checked) + span,
  [type="checkbox"]:checked + span {
    cursor: pointer;
    padding-left: 2.6em;
    position: relative;
  }

  /* Checkbox */
  [type="checkbox"]:not(:checked) + span:before,
  [type="checkbox"]:checked + span:before {
    background: white;
    border: .25em solid #aaa;
    border-radius: .5em;
    @include box-shadow(inset 0 1px .175em rgba(0,0,0,.3));
    content: '';
    height: 2em;
    left: 0;
    position: absolute;
    top: 50%;
    @include transform(translateY(-50%));
    @include transition(border-color .15s);
    width: 2em;
  }

  /* Tick */
  [type="checkbox"]:not(:checked) + span:after,
  [type="checkbox"]:checked + span:after {
    color: #666;
    border: solid;
    border-width: 0 .25em .25em 0;
    content: '';
    height: 1em;
    left: .75em;
    margin-top: -.5em;
    position: absolute;
    top: 50%;
    top: calc(50% - 1px);
    @include transform-origin(50% 50%);
    @include transition-property(opacity, transform);
    @include transition-duration(.15s);
    width: .5em;
  }

  /* Checkbox changes */
  [type="checkbox"]:checked + span:before {
    border-color: $brand-color-2;
  }

  /* Tick changes */
  [type="checkbox"]:not(:checked) + span:after {
    opacity: 0;
    @include transform(rotate(45deg) scale(0));
  }
  [type="checkbox"]:checked + span:after {
    opacity: 1;
    @include transform(rotate(45deg) scale(1));
  }

  /* Disabled checkbox */
  [type="checkbox"]:disabled:not(:checked) + span:before,
  [type="checkbox"]:disabled:checked + span:before {
    background-color: #ddd;
    border-color: #bbb;
    @include box-shadow(none);
  }
  [type="checkbox"]:disabled:checked + span:after {
    color: #999;
  }
  [type="checkbox"]:disabled + span {
    color: #aaa;
    cursor: default;
  }

  /* Accessibility */
  [type="checkbox"]:checked:focus + span:before,
  [type="checkbox"]:not(:checked):focus + span:before {
    //border: dotted blue;
    //border-color: #66afe9;
    @include box-shadow(inset 0 1px .175em rgba(0,0,0,.3), 0 0 .45em rgba(102, 175, 233, .6));
  }

  /* Hover style for information */
  [type="checkbox"]:not(:disabled) + span.label:hover:before {
    //border-color: #4778d9;
    @include box-shadow(inset 0 1px .175em rgba(0,0,0,.3), 0 0 .45em rgba(102, 175, 233, .6));
  }
}

/* Checkbox on right-hand-side */
.xcheckbox-right {

  /* Base styling */
  [type="checkbox"]:not(:checked) + span,
  [type="checkbox"]:checked + span {
    padding-right: 2.6em;
    padding-left: 0;
  }

  /* Checkbox */
  [type="checkbox"]:not(:checked) + span:before,
  [type="checkbox"]:checked + span:before {
    left: auto;
    right: 0;
    @include transform(translateY(-50%));
  }

  /* Tick */
  [type="checkbox"]:not(:checked) + span:after,
  [type="checkbox"]:checked + span:after {
    left: auto;
    right: .75em;
  }
}

/* Large checkbox */
.xcheckbox-large {

}

/* Small checkbox */
.xcheckbox-small {
  font-size: 80%;
}
